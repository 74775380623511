import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ROUTER_UTILS } from '@core/utils/router.utils';
import { AuthGuard, NoAuthGuard } from '@core/guards';

const routes: Routes = [
  {
    path: ROUTER_UTILS.auth.root,
    loadChildren: async () => (await import('@pages/auth/auth.module')).AuthModule,
    canLoad: [NoAuthGuard],
  },
  {
    path: ROUTER_UTILS.base.home,
    pathMatch: 'full',
    redirectTo: `${ROUTER_UTILS.auth.root}/${ROUTER_UTILS.auth.login}`,
    canLoad: [AuthGuard],
  },
  {
    path: ROUTER_UTILS.dashboard.root,
    loadChildren: async () => (await import('@pages/dashboard/dashboard.module')).DashboardModule,
    canLoad: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
