<nz-header>
	<nz-row>
		<nz-col class="logo-brand-app" nzSpan="8">
			<svg class="logo" width="130" height="32" role="img">
				<use xlink:href="#niqlogo" />
			</svg>
			<div class="app-title">Ecommerce Accelerator</div>
		</nz-col>

		<nz-col class="account-icon" nzSpan="1" nzOffset="15">
			<div class="setting-icon">
				<configration-setting #configSetting></configration-setting>
			</div>
			<div *ngIf="isLoggedIn$ | async" class="header-item" [nzTooltipTitle]="userAccount" nz-tooltip
				nzTooltipPlacement="bottom" nzTooltipOverlayClassName="user-account">
				<svg width="24" height="24">
					<use xlink:href="#account-icon"></use>
				</svg>
			</div>

			<ng-template #userAccount>
				<ul class="account-menu" nz-menu nzMode="inline" nzTheme="dark" nzSelectable="false">
					<!-- <li class="account-sub-menu" nz-menu-item>
						{{ 'menu.profile' | translate }}
					</li> -->
					<li class="account-sub-menu" nz-submenu [nzTitle]="'auth.downloadUserGuild' | translate">
						<ul class="account-sub-menu">
							<li nz-menu-item (click)="download('EN')">EN</li>
							<li nz-menu-item (click)="download('KR')">KR</li>
						</ul>
					</li>
					<li class="account-sub-menu" (click)="openNotificationContent()" nz-menu-item>{{ 'auth.notification'
						| translate}}
					<li class="account-sub-menu" (click)="signOut()" nz-menu-item>{{ 'auth.signOut' | translate}}
					</li>
				</ul>
			</ng-template>
		</nz-col>
	</nz-row>
</nz-header>
<notification-list #notification [(notificationListVisible)]="notificationListVisible"></notification-list>
<svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
	<symbol id="niqlogo" viewBox="0 0 200 85">
		<defs>
			<style>
				.b {
					fill: #fff;
				}
			</style>
		</defs>
		<rect class="b" x="84.87" y="1.81" width="18.39" height="73.42" />
		<path class="b"
			d="M58.57,75.52c-2.58,0-5.1-1.09-6.88-3.09L18.39,34.9v40.33H0V10.67C0,6.85,2.36,3.42,5.93,2.07c3.57-1.36,7.61-.36,10.14,2.5L49.37,42.1V1.81h18.39V66.33c0,3.82-2.36,7.24-5.93,8.6-1.06,.4-2.17,.6-3.26,.6Z" />
		<path class="b"
			d="M200,71.83l-13.58-13.58c3.49-5.77,5.5-12.54,5.5-19.76C191.91,17.35,174.72,.16,153.58,.16s-38.33,17.19-38.33,38.33,17.19,38.33,38.33,38.33c7.26,0,14.05-2.03,19.84-5.55l13.57,13.57,13.01-13.01Zm-66.35-33.34c0-10.99,8.94-19.94,19.94-19.94s19.94,8.94,19.94,19.94-8.94,19.94-19.94,19.94-19.94-8.94-19.94-19.94Z" />

	</symbol>
</svg>

<svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
	<symbol id="account-icon" viewBox="0 0 24 24">
		<title>Account Icon</title>
		<path stroke-linecap="round" stroke-linejoin="round" stroke-width=".5" fill="currentColor"
			d="M12.0004919,2 C14.9886047,2 17.4109488,4.46243388 17.4109488,7.5 C17.4109488,9.5597022 16.2971847,11.3549656 14.6484833,12.2973839 L20.460623,15.1416122 L22,22 L2,22 L3.54036072,15.1416122 L9.3525004,12.2973839 C7.70379902,11.3549656 6.59003492,9.5597022 6.59003492,7.5 C6.59003492,4.46243388 9.012379,2 12.0004919,2 Z M12.0004919,13.219 L5.24725788,16.525 L4.466,20 L19.533,20 L18.7527421,16.525 L12.0004919,13.219 Z M12.0004919,4 C10.0989655,4 8.55747381,5.56700338 8.55747381,7.5 C8.55747381,9.43299662 10.0989655,11 12.0004919,11 C13.9020182,11 15.4435099,9.43299662 15.4435099,7.5 C15.4435099,5.56700338 13.9020182,4 12.0004919,4 Z">
		</path>
	</symbol>
</svg>