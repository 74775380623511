import { Inject, Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';
import { DEFAULT_TIMEOUT } from '@core/utils';

@Injectable()
export class TimeoutInterceptor implements HttpInterceptor {
  constructor(@Inject(DEFAULT_TIMEOUT) protected defaultTimeout: number) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const _timeout = Number(request.headers.get('timeout')) || this.defaultTimeout;
    request.headers.delete('timeout');
    return next.handle(request).pipe(timeout(_timeout));
  }
}
