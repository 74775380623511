import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Result } from '@app/models/result';
import { SearchCondition } from '@app/models/search-condition';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  public currentFilesBS: BehaviorSubject<string>;

  constructor(private http: HttpClient, private router: Router) {
    this.currentFilesBS = new BehaviorSubject<string>('');
  }

  public get currentFiles(): string {
    return this.currentFilesBS.value;
  }

  getFilesList(syncType: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/ea/sync/getFileTree/${syncType}`)
  }
  getSubFileList(syncType: string, filePath?: string): Observable<any> {
    return this.http.post(`${environment.apiUrl}/ea/sync/getSubFile/`, { path: filePath, syncType: syncType })
  }
  getSyncTypes(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/ea/sync/getSyncTypes`)
  }
  getEnvs(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/ea/sync/getEnvs`)
  }
  getStatus(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/ea/task/status`)
  }
  getRecordList(params: SearchCondition): Observable<any> {
    return this.http.post(`${environment.apiUrl}/ea/task/getTasksWithPage`, { ...params })
  }

  syncData(params: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/ea/sync/syncData`, { ...params })
  }
  downloadUserGuild(language: string): Observable<any> {
    const options: Object = {
      responseType: 'arraybuffer',
      observe: 'response'
    }
    return this.http.post(`${environment.apiUrl}/ea/fileResource/userGuid/download`, language, options)
  }
}
