import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd/message';
import { TranslateService } from '@ngx-translate/core'
import { getItem, setItem, StorageItem } from '@core/utils';
import { Router } from '@angular/router';
import { ROUTER_UTILS } from '@app/@core/utils/router.utils';
import { UploadService } from '@app/services/upload.service';
import { FileSaverService } from 'ngx-filesaver';
import { FileResourceService } from '@app/services/fileResource.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent implements OnInit {
  isLoggedIn$!: Observable<boolean>;
  countryList: Array<any> = [];
  lang: string = ''
  currentCountry: string = ''
  currentCategory: string = ''
  drawerVisible: boolean = false
  notificationListVisible: boolean = false
  currentConfirmGroupId_obs: any

  @ViewChild("notification")
  notification!: any;

  @ViewChild("configSetting")
  configSetting: any;
  constructor(public authService: AuthService, private message: NzMessageService,
    private translate: TranslateService, private router: Router,
    private uploadService: UploadService, private fileSaverService: FileSaverService,
    private fileResourceService: FileResourceService, private notificationService: NzNotificationService) {

  }

  ngOnInit(): void {
    this.lang = this.translate.currentLang;
    this.isLoggedIn$ = this.authService.isLoggedInSubject;
    this.isLoggedIn$.subscribe((isLoggedIn: any) => {
      this.currentCountry = <string>(getItem(StorageItem.Country) || '')
      this.currentCategory = <string>(getItem(StorageItem.Category) || '')
      if (isLoggedIn && (!this.currentCategory || !this.currentCountry)) {
        this.configSetting.configVisible = true
      }
    });

  }
  ngAfterViewInit(): void {
    const token = <string>(getItem(StorageItem.Token) || '');
    if (!token) {
      const currentHref = decodeURIComponent(location.href)
      if (!currentHref.includes(ROUTER_UTILS.auth.login) && !currentHref.includes(ROUTER_UTILS.auth.resetPassword)) {
        this.router.navigate(['/auth/login/'])
      }
    }
    this.currentConfirmGroupId_obs = this.authService.confirmGroupIdSubject.subscribe((isComfirmed: Boolean) => {
      if (isComfirmed) {
        this.fileResourceService.isActive().subscribe(res => {
          if (res.statusCode === 200 && res.data) {
            setItem(StorageItem.Active, res.data);
            this.notification.autoOpen()
          }
        })
      }
    })
  }
  signOut(): void {
    this.authService.signOut();
  }
  selectCountry() {
    window.localStorage.setItem('currentCountry', this.currentCountry);
  }
  drawerVisibleChange(visiable: any) {
    this.drawerVisible = visiable;
  }
  download(language: string) {
    const name = 'User_Guide_' + language + '.pdf';
    this.uploadService.downloadUserGuild(language)
      .subscribe((res: any) => {
        const blob = new Blob([res.body]);
        this.fileSaverService.save(blob, name);
      })
    this.notificationService.create(
      "success",
      'Download',
      'Downlload user guide success, please wait a moment.'
    );
  }
  openNotificationContent() {
    this.notification.onClickSearch()
    this.notificationListVisible = true;
  }
}
