import { NgModule } from '@angular/core';
import { HeaderComponent } from './header.component';
import { SharedModule } from '@core/shared/shared.module';
import { ConfigrationSettingModule } from '../configration-setting/configration-setting.module';
import { NotificationModule } from '../notification/notification.module';

@NgModule({
  declarations: [HeaderComponent],
  imports: [SharedModule, ConfigrationSettingModule, NotificationModule],
  exports: [HeaderComponent],
})
export class HeaderModule { }
