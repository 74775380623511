import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { getItem, StorageItem } from '@core/utils';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = <string>(getItem(StorageItem.Token) || '');

    if (!!token) {
      request = request.clone({
        headers: request.headers.set('token', token).set('Content-Type', 'application/json'),
      });
    } else {
      request = request.clone({
        headers: request.headers.set('Content-Type', 'application/json'),
      });
    }
    return next.handle(request);
  }
}
