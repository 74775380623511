import { NgModule } from '@angular/core';
import { ConfigrationSettingComponent } from './configration-setting';
import { SharedModule } from '@core/shared/shared.module';

@NgModule({
  declarations: [ConfigrationSettingComponent],
  imports: [SharedModule],
  exports: [ConfigrationSettingComponent],
})
export class ConfigrationSettingModule { }
