<span *ngIf="isLoggedIn$ | async" nz-icon class="edit" nzType="setting" nzTheme="outline"
	(click)="onClickEdit()"></span>
<nz-modal [nzStyle]="{ top: '200px' }" [(nzVisible)]="configVisible" [nzWidth]="600"
	[nzTitle]="'drawer.setting' | translate" nzOkText="Ok" nzCancelText="Cancel" (nzOnOk)="clickCancel()"
	(nzOnCancel)="clickCancel()" nzClassName="modal-content" [nzMaskClosable]="false" [nzFooter]="null">
	<ng-container *nzModalContent>
		<form nz-form>
			<nz-form-item>
				<nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="country">{{'drawer.country' | translate}}
				</nz-form-label>
				<nz-form-control [nzSm]="16" [nzXs]="24">
					<nz-select name="country" #country [(ngModel)]="currentCountry" (ngModelChange)="selectCountry()">
						<nz-option *ngFor="let item of countryList" [nzValue]="item.countryCode"
							[nzLabel]="item.countryCode"></nz-option>
					</nz-select>
				</nz-form-control>
			</nz-form-item>
			<nz-form-item>
				<nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="country">{{'drawer.category' | translate}}
				</nz-form-label>
				<nz-form-control [nzSm]="16" [nzXs]="24">
					<nz-select name="category" #category [(ngModel)]="currentCategory"
						(ngModelChange)="selectCategory()">
						<nz-option *ngFor="let item of categoryList" [nzValue]="item.key"
							[nzLabel]="item.value"></nz-option>
					</nz-select>
				</nz-form-control>
			</nz-form-item>

			<nz-form-item class="bottom-button">
				<button nz-button class="cancel-button" nzType="primary"
					(click)="clickCancel()">{{'cancel'|translate}}</button>
				<button nz-button class="confirm-button" nzType="primary" (click)="clickConform()"
					[disabled]="!currentCountry || !currentCategory">{{'confirm'|translate}}</button>
			</nz-form-item>
		</form>

	</ng-container>
</nz-modal>