import { getItem, StorageItem } from "@app/@core/utils/local-storage.utils";

interface Component { }

type ComponentClass = { new(...any: any[]): Component };

class ComponentRegistry {

    registry = new Map<string, ComponentClass>();

    getTypeFor(name: string): ComponentClass {
        if (name.match('-'))
            name = this.getComponentNameFromTemplate(name);
        let componentClass = this.registry.get(name);
        if (componentClass == null) throw new Error(`${name} was not found in the COMPONENTREGISTRY.registry`);
        return componentClass;
    }
    getComponentNameFromTemplate(name: string): string {
        const words: string[] = name.split('-');
        words.splice(0, 1); // remove the prefix
        for (let i = 0; i < words.length; i++) {
            const capitalized = words[i].charAt(0).toUpperCase() + words[i].slice(1);
            words.splice(i, 1, capitalized);
        }
        const componentName = words.join('') + 'Component';
        return componentName;
    }

    register(name: string, cls: ComponentClass): void {
        this.registry.set(name, cls);
    }
    clear() {
        this.registry.clear()
    }

}

export const COMPONENTREGISTRY = new ComponentRegistry();

export function registerComponent(name: string, componentClass: ComponentClass): void {
    const env = getItem(StorageItem.MenuEnv) + '_';
    COMPONENTREGISTRY.register(env + name, componentClass);
}

export function register(componentClass: ComponentClass): void {
    COMPONENTREGISTRY.register(componentClass.name, componentClass);
}
export function clear() {
    COMPONENTREGISTRY.clear()
}