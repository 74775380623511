<ul *ngIf="!isCollapsed" class="nav-menu-list" nz-menu nzMode="inline">
  <ng-container *ngFor="let item of navigationList">
    <li class="nav-sub-menu-list" nz-submenu *ngIf="item.subMenuDTOS && item.subMenuDTOS.length>0; else liItem"
      [nzOpen]="isOpenSubMenu(item.subMenuDTOS)" [nzTitle]="item.menuName">
      <ul>
        <li *ngFor="let e of item.subMenuDTOS" nz-menu-item [nzSelected]="isSelected(e)" (click)="navMenuClick(e)">
          {{e.menuName }}
        </li>
      </ul>
    </li>
    <ng-template #liItem>
      <li nz-menu-item [nzSelected]="isSelected(item)" (click)="navMenuClick(item)">
        {{item.menuName }}
      </li>
    </ng-template>
  </ng-container>
</ul>
<!--窄版目录-->
<ul *ngIf="isCollapsed" class="nav-menu-list" nz-menu nzMode="vertical">
  <ng-container *ngFor="let item of navigationList">
    <li *ngIf="item.subMenuDTOS && item.subMenuDTOS.length>0; else liItem" nz-submenu
      [nzTitle]="item.menuName.slice(0,5)">
      <ul class="ant-menu-submenu-vertical">
        <li class="ant-menu-submenu-vertical-item" *ngFor="let e of item.subMenuDTOS" nz-menu-item
          [nzSelected]="isSelected(e)" (click)="navMenuClick(e)">
          {{e.menuName }}
        </li>
      </ul>
    </li>
    <!-- <li class="nav-sub-menu-list" nz-submenu *ngIf="item.subMenuDTOS && item.subMenuDTOS.length>1; else liItem"
      [nzOpen]="isOpenSubMenu(item.subMenuDTOS)" [nzTitle]="item.menuName">
      <ul>
        <li *ngFor="let e of item.subMenuDTOS" nz-menu-item [nzSelected]="isSelected(e)" (click)="navMenuClick(e)">
          {{e.menuName }}
        </li>
      </ul>
    </li> -->
    <ng-template #liItem>
      <li nz-menu-item nz-tooltip [nzTooltipTitle]="item.menuName" nzTooltipPlacement="right"
        [nzSelected]="isSelected(item)" (click)="navMenuClick(item)">
        {{item.menuName.slice(0,7) }}
      </li>
    </ng-template>
  </ng-container>
</ul>