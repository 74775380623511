<nz-modal [nzStyle]="{ top: '30px' }" [(nzVisible)]="previewVisible" [nzWidth]="1100" nzOkText="Ok"
	nzCancelText="Cancel" (nzOnOk)="clickCancel()" (nzOnCancel)="clickCancel()" nzClassName="modal-content"
	[nzMaskClosable]="false" [nzFooter]="null" [nzTitle]="modalTitle">
	<ng-template #modalTitle>{{name}}
		<!-- <button *ngIf="active" class="not-show" nzType="primary" nz-button nzGhost (click)="notShow()"
			[disabled]="!checked">{{'notification.notShow' |
			translate}}</button> -->
		<label *ngIf="active" nz-checkbox class="not-show-checked" [(ngModel)]="checked">{{'notification.notShow' |
			translate}}</label>
	</ng-template>
	<ng-container *nzModalContent>
		<nz-spin [nzSpinning]="loading" nzTip="Loading...">
			<pdf-viewer class="pdf-preview" [src]="pdfSrc" [render-text]="true" [original-size]="false"
				style="height: 750px" [autoresize]="true"></pdf-viewer>
		</nz-spin>
	</ng-container>
</nz-modal>