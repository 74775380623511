<nz-table #sortTable [nzData]="tableData" nzTableLayout="fixed" [nzFrontPagination]="tableData.length>10"
	[nzLoading]="loading" [nzTotal]="pageVo.total" [nzPageSize]="pageVo.pageSize" [nzPageIndex]="pageVo.pageIndex"
	(nzQueryParams)="onClickSearch($event)">
	<thead>
		<tr>
			<th *ngFor="let column of columns" [nzSortFn]="column.compare" [nzSortPriority]="column.priority">
				{{ column.title | translate}}
			</th>
		</tr>
	</thead>
	<tbody>
		<tr *ngFor="let data of sortTable.data">
			<td *ngFor="let column of columns">
				<div *ngIf="!column.custom">{{ data[column.id] }}</div>
				<ng-template *ngIf="column.custom" [ngTemplateOutlet]="status"
					[ngTemplateOutletContext]="{ $implicit: column.id }">
					<ng-content></ng-content>
					<!-- <ng-content select=".blue"></ng-content> -->
					<!-- <ng-container *ngTemplateOutlet="column.id"> 
					</ng-container>-->
				</ng-template>
			</td>
		</tr>
	</tbody>
</nz-table>