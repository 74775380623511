import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { getItem, setItem, StorageItem, timeFormatSeconds } from '@app/@core/utils';
import { SearchCondition } from '@app/models/search-condition';
import { TableColumn } from '@app/models/table-column';
import { FilterService } from '@app/services/filter.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@services/auth.service';
import * as _ from 'lodash';
import { NzMessageService } from 'ng-zorro-antd/message';
import { BehaviorSubject, finalize, firstValueFrom, Observable } from 'rxjs';

@Component({
  selector: 'configration-setting',
  templateUrl: './configration-setting.html',
  styleUrls: ['./configration-setting.less']
})
export class ConfigrationSettingComponent implements OnInit {
  configVisible: boolean = false
  loading = false;
  lang: string = ''
  countryList: Array<any> = []
  currentCountry: string = ''
  currentCategory: string = ''
  categoryList: Array<any> = []
  isLoggedIn$!: Observable<boolean>;
  conformCategory$!: BehaviorSubject<boolean>;
  @ViewChild("country")
  country!: any;
  @ViewChild("category")
  category!: any;

  constructor(public authService: AuthService, private translate: TranslateService, private filterService: FilterService, private message: NzMessageService, public elRef?: ElementRef) {
    this.conformCategory$ = this.authService.confirmGroupIdSubject
  }
  ngOnInit(): void {
    this.lang = this.translate.currentLang;
    this.isLoggedIn$ = this.authService.isLoggedInSubject;

    this.isLoggedIn$.subscribe((isLoggedIn: any) => {
      if (isLoggedIn) {
        //获取国家列表
        this.countryList = []
        this.categoryList = []
        this.authService.getCountryAndCategory().subscribe(res => {
          if (res.statusCode === 200) {
            if (res.data && res.data.length) {
              this.countryList = res.data
              this.currentCountry = <string>getItem(StorageItem.Country) || ''
              this.currentCategory = <string>getItem(StorageItem.Category) || ''
              if (!this.currentCountry) {
                this.country?.writeValue(res.data[0].countryCode);
                this.currentCountry = res.data[0].countryCode
                this.categoryList = res.data[0].categorys
              } else {
                const index = _.findIndex(this.countryList, o => { return o.countryCode == this.currentCountry; });
                if (index > -1) this.categoryList = this.countryList[index].categorys
              }
              if (!this.currentCategory) {
                this.currentCategory = this.categoryList[0].key
                this.category?.writeValue(this.currentCategory);
              }

            } else {
              //没有配置国家
              this.translate.get('auth.countryEmptyMsg').subscribe(res => {
                const errMsg = res;
                this.message.create('error', errMsg);
              });
            }
          }
        })
      }
    })
  }
  onClickEdit() {
    this.configVisible = true
  }
  clickCancel() {
    if (!getItem(StorageItem.Country) || !getItem(StorageItem.Category)) {
      this.authService.signOut()
    }
    this.configVisible = false
  }
  clickConform() {
    setItem(StorageItem.Country, this.currentCountry);
    setItem(StorageItem.Category, this.currentCategory);
    //根据category获取segement列表
    this.filterService.getSegmentMapping({ categoryCode: this.currentCategory }).subscribe(res => {
      if (res.statusCode === 200) {
        setItem(StorageItem.Segement, res.data);
        this.conformCategory$.next(true)
        this.configVisible = false
      } else {
        this.translate.get('filter.getSegementErrorMsg').subscribe(res => {
          const errMsg = res;
          this.message.create('error', errMsg);
        });
      }
    })
  }

  selectCountry() {
    const index = _.findIndex(this.countryList, o => { return o.countryCode == this.currentCountry; });
    this.categoryList = this.countryList[index].categorys
    if (this.currentCategory) {
      //在当前categoryList找,如果没找到,就赋值第一个
      const index = _.findIndex(this.categoryList, o => { return o.key == this.currentCategory; });
      if (index === -1) {
        this.currentCategory = this.categoryList[0].key
      }
    }
  }
  selectCategory() {

  }
}
