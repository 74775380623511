import { Component, OnInit } from '@angular/core';
import { I18nService } from '@core/i18n/i18n.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit {
  constructor(private i18nService: I18nService) { }

  ngOnInit(): void {
    this.runGlobalServices();
  }

  private runGlobalServices(): void {
    this.i18nService.init();
  }
}