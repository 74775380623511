import { NgModule } from '@angular/core';
import { SliderComponent } from './slider.component';
import { SharedModule } from '@core/shared/shared.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [SliderComponent],
  imports: [SharedModule, RouterModule],
  exports: [SliderComponent]
})
export class SliderModule {}
