<nz-modal [nzStyle]="{ top: '200px' }" [(nzVisible)]="notificationListVisible" [nzWidth]="800"
	[nzTitle]="'notification.title' | translate" nzOkText="Ok" nzCancelText="Cancel" (nzOnOk)="clickCancel()"
	(nzOnCancel)="clickCancel()" nzClassName="modal-content" [nzMaskClosable]="false" [nzFooter]="null">
	<ng-container *nzModalContent>
		<div class="config-table">
			<nz-table #sortTable class="black-background-table" [nzData]="tableData" nzTableLayout="fixed"
				[nzFrontPagination]="true" [nzLoading]="loading" [nzScroll]="{y:'300px'}" nzSize="small"
				[nzShowPagination]="tableData.length>10">
				<ng-template #noData let-selectedList>
					<div>No data</div>
				</ng-template>
				<thead>
					<tr>
						<th *ngFor="let column of columns" [nzSortFn]="column.compare"
							[nzSortPriority]="column.priority" [nzWidth]="column.width?column.width:null">
							{{ column.title | translate}}
						</th>
					</tr>
				</thead>
				<tbody>
					<ng-container *ngFor="let item of sortTable.data">
						<ng-container *ngFor="let data of mapOfExpandedData[item.fullFileName]">
							<tr *ngIf="(data.parent && data.parent.expand) || !data.parent">
								<td [nzIndentSize]="data.level! * 20" [nzShowExpand]="!!data.children"
									[(nzExpand)]="data.expand" (nzExpandChange)="collapse(tableData, data, $event)">
									{{ data.fullFileName }}
								</td>
								<td>
									{{data.lastModifiedDate | date: 'dd/MM/yyyy HH:mm:ss'}}
								</td>
								<td>
									<button nz-button class="apply-button" nzSize="small"
										(click)="clickPreview(data)">{{
										'notification.preview' | translate}}</button>
								</td>
							</tr>
						</ng-container>
					</ng-container>
				</tbody>
			</nz-table>
		</div>
	</ng-container>
</nz-modal>
<notification-preview #preview [(previewVisible)]="previewVisible"></notification-preview>