import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzModalRef } from 'ng-zorro-antd/modal/modal-ref';
import { I18nService } from '@core/i18n/i18n.service';
import { DEFAULT_LOCALE_LANG, LocaleLang } from '@core/i18n/locale-lang.enum';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { AuthService } from '@services/auth.service';
import { Lang } from '@app/models/lang';

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {
  modalMsgMap = new Map<string | number, Lang>([
    ['AuthenticationError_TokenInvalidOrExpired', { EN: 'Login expired', CN: '登录过期' }]
  ]);

  commonMsgMap = new Map<string, Lang>([
    ['INTERNAL_SERVER_ERROR', { EN: 'Internal Server Error!', CN: '服务器内部错误！' }],
    ['AuthenticationError_WrongAccountOrPassword', { EN: 'Wrong account or password ', CN: '用户名或密码错误！' }],
    ['PREFERENCE_NAME_EXISTED', { EN: 'Name alredy existed', CN: '名字已存在' }]
  ]);

  currLang: LocaleLang = DEFAULT_LOCALE_LANG;
  constructor(
    private notification: NzNotificationService,
    private modal: NzModalService,
    private i18nService: I18nService,
    private authService: AuthService
  ) {
    this.i18nService.currentLang$.subscribe(lang => {
      this.currLang = lang;
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        this.handleErrMsg(err);
        return throwError(() => err);
      }),
    );
  }

  async handleErrMsg(err: HttpErrorResponse) {
    let { error } = err;
    if (error instanceof Blob) {
      const text = await new Response(error).text();
      error = JSON.parse(text);
    }
    const { data, exception = {}, statusCode } = error || {};
    const { msg, msgKey } = exception || {};
    const { message } = data || {};

    if (this.modalMsgMap.has(msgKey)) {
      const modalMsg = (this.modalMsgMap.get(msgKey) || {})[this.currLang];
      if (modalMsg) {
        this.showErrorMsg(modalMsg, true);
        this.authService.signOut();
        return;
      }
    }
    const errMsg = (this.commonMsgMap.get(msgKey) || {})[this.currLang] || message || msg;
    this.showErrorMsg(errMsg);
  }

  errModal?: NzModalRef;
  showErrorMsg(errMsg: string, isShowModal?: boolean) {
    if (!errMsg) return;
    if (isShowModal) {
      if (!this.errModal) {
        this.errModal = this.modal.error({
          nzTitle: `${errMsg}`,
          nzOkText: 'OK',
          nzClosable: false,
          nzOnOk: () => {
            this.errModal?.destroy();
            this.errModal = undefined;
          },
        });
      }
    } else {
      this.notification.error('', errMsg, { nzKey: errMsg });
    }
  }
}
