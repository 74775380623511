import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SHARED_ZORRO_MODULES } from './shared-zorro.module';
import { SanitizeHtmlPipe } from '@core/pipes/sanitize-html.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SimpleTableComponent } from '@app/components/simple-table/simple-table.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';

@NgModule({
  declarations: [SanitizeHtmlPipe, SimpleTableComponent],
  imports: [SHARED_ZORRO_MODULES, CommonModule, FormsModule, ReactiveFormsModule, TranslateModule],
  exports: [SHARED_ZORRO_MODULES, CommonModule, SanitizeHtmlPipe, FormsModule, ReactiveFormsModule,
    TranslateModule, SimpleTableComponent, PdfViewerModule],
})
export class SharedModule { }
