import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { TableColumn } from '@app/models/table-column';
import { FileResourceService } from '@app/services/fileResource.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@services/auth.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { finalize, firstValueFrom } from 'rxjs';

@Component({
  selector: 'notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.less']
})
export class NotificationListComponent implements OnInit {
  @Input()
  notificationListVisible: boolean = false
  @Output() notificationListVisibleChange = new EventEmitter<boolean>();
  pageVo: any = { total: 10, pageSize: 10, pageNum: 1 }
  loading = false;
  tableData: Array<any> = []
  columns: Array<TableColumn> = []

  previewVisible: boolean = false

  @ViewChild("sortTable")
  sortTable!: any;

  @ViewChild("preview")
  preview!: any;
  mapOfExpandedData: { [fullFileName: string]: any[] } = {};
  constructor(public authService: AuthService, private translate: TranslateService,
    private fileResourceService: FileResourceService, private message: NzMessageService) {
  }
  ngOnInit(): void {
    this.initColumns()
    // this.onClickSearch()

  }
  convertTreeToList(root: any): any[] {
    const stack: any[] = [];
    const array: any[] = [];
    const hashMap = {};
    stack.push({ ...root, level: 0, expand: false });

    while (stack.length !== 0) {
      const node = stack.pop()!;
      this.visitNode(node, hashMap, array);
      if (node.children) {
        for (let i = node.children.length - 1; i >= 0; i--) {
          stack.push({ ...node.children[i], level: node.level! + 1, expand: false, parent: node });
        }
      }
    }

    return array;
  }
  visitNode(node: any, hashMap: { [fullFileName: string]: boolean }, array: any[]): void {
    if (!hashMap[node.fullFileName]) {
      hashMap[node.fullFileName] = true;
      array.push(node);
    }
  }
  //初始化表头
  initColumns() {
    this.translate.get(['notification.fileName', 'notification.publishDate', 'operator']).subscribe(res => {
      this.columns = [
        { id: 'fullFileName', title: res['notification.fileName'], width: "200px", custom: true },
        { id: 'lastModifiedDate', title: res['notification.publishDate'], width: "80px" },
        { id: 'operator', title: res['operator'], width: "60px", custom: true }
      ]
    });

  }
  clickCancel() {
    this.notificationListVisible = false
    this.notificationListVisibleChange.emit(false)
  }

  //查询记录
  async onClickSearch() {
    this.loading = true
    this.tableData = []
    const res = await firstValueFrom(this.fileResourceService.getFilesList("Notification").pipe(finalize(() => this.loading = false)))
    if (res.statusCode === 200) {
      this.tableData = res.data
      this.tableData.forEach(item => {
        this.mapOfExpandedData[item.fullFileName] = this.convertTreeToList(item);
      });
    } else {
      this.translate.get('userPerference.searchErrorMsg').subscribe(res => {
        const errorInfo = res;
        this.message.error(errorInfo);
      });
    }
    this.loading = false
  }
  cancel(): void {
    this.message.info('click cancel');
  }
  clickPreview(file: any) {
    this.previewVisible = true
    this.preview.initData(file.fileFullPath)
  }
  collapse(array: any[], data: any, $event: boolean): void {
    if (!$event) {
      if (data.children) {
        data.children.forEach((d: { fullFileName: any; }) => {
          const target = array.find(a => a.fullFileName === d.fullFileName)!;
          if (target) {
            target.expand = false;
            this.collapse(array, target, false);
          }
        });
      } else {
        return;
      }
    }
  }
  async autoOpen() {
    // this.notificationListVisible = true
    await this.onClickSearch()
    this.preview.active = true;
    if (this.tableData[0]) this.clickPreview(this.tableData[0])
  }
}
