<nz-layout class="ant-layout-fixed-header">
  <app-header></app-header>

  <nz-layout class="ant-layout-fixed-content">
    <nz-sider *ngIf="isLoggedIn$ | async" nzCollapsible [(nzCollapsed)]="isCollapsed" [nzTrigger]="null"
      [nzWidth]="isCollapsed ? '50px' :'230px'">
      <app-slider [isCollapsed]="isCollapsed"></app-slider>
      <i class="trigger" nz-icon [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"
        (click)="isCollapsed = !isCollapsed"></i>
    </nz-sider>

    <nz-content>
      <ng-content></ng-content>
    </nz-content>
  </nz-layout>
</nz-layout>