import { Injectable } from '@angular/core';
import {
  cleanAppStorage,
  getItem,
  setItem,
  StorageItem,
  toMonth,
  treeToList,
} from '@core/utils';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { LoginUser } from '@app/models/login-user';
import { map } from 'rxjs/operators';
import { ROUTER_UTILS } from '@core/utils/router.utils';
import { Router } from '@angular/router';
import { isEmpty, pick } from 'lodash';
import { clear } from '@app/services/component.register';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<LoginUser>;
  public isLoggedInSubject: BehaviorSubject<boolean>;
  public confirmGroupIdSubject: BehaviorSubject<boolean>;
  public currentCountry: BehaviorSubject<string>;
  public currentMenuEnv: BehaviorSubject<string>;

  constructor(private http: HttpClient, private router: Router) {
    this.currentUserSubject = new BehaviorSubject<LoginUser>(
      JSON.parse(<string>getItem(StorageItem.AuthUser) || '{}')
    );
    this.isLoggedInSubject = new BehaviorSubject<boolean>(
      !!getItem(StorageItem.Token)
    );
    this.confirmGroupIdSubject = new BehaviorSubject<boolean>(
      !!getItem(StorageItem.Category) && !!getItem(StorageItem.Country)
    );
    this.currentCountry = new BehaviorSubject<string>('');
    this.currentMenuEnv = new BehaviorSubject<string>('');
  }

  public get currentUser(): LoginUser {
    return this.currentUserSubject.value;
  }

  public get isLoggedIn(): boolean {
    return this.isLoggedInSubject.getValue();
  }

  public get confirmGroupId(): boolean {
    return this.confirmGroupIdSubject.getValue();
  }

  // signIn(): void {
  //   this.isLoggedInSubject.next(true);
  //   setItem(StorageItem.Token, 'token');
  //   this.router.navigate(['/dashboard/overview']);
  // }

  signIn(payload: any): Observable<LoginUser | undefined> {
    const url = `${environment.apiUrl}/ea/account/login`;
    // const response = this.http.post<LoginUser>(url, payload)
    return this.http
      .post<LoginUser>(url, payload, { observe: 'response' })
      .pipe(
        map((response: any) => {
          if (!response.body.data) return;

          const authKey = response.body.data['authKey'];
          if (!isEmpty(authKey)) {
            // 需要短信验证码(二步登录验证)
            return response.body.data;
          }
          const user: LoginUser = pick(response.body.data, [
            'username',
            'authorities',
            'groupId'
          ]);
          const token = response.headers.get('token');

          if (!token) return;

          // store user details and jwt token in local storage to keep user logged in between page refreshes
          setItem(StorageItem.AuthUser, JSON.stringify(user));
          setItem(StorageItem.Token, token);
          this.currentUserSubject.next(user);
          this.isLoggedInSubject.next(true);
          return user;
        })
      );
  }

  signOut(): void {
    const url = `${environment.apiUrl}/ea/logout`;
    this.http.get(url).subscribe(res => {
      this.isLoggedInSubject.next(false);
      this.confirmGroupIdSubject.next(false);
      clear()
      cleanAppStorage();
      this.router.navigateByUrl('', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/auth/login']);
      });
    })
  }

  getCountryList(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/ea/country/authList`)
  }

  getCountryAndCategory(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/ea/authGroupUnion/getCountryCategoryCombine`)
  }

  sendMail(userName: string): Observable<any> {
    return this.http.post(`${environment.apiUrl}/ea/user/sendUpdatePasswordMail`, { userName })
  }

  checkUpdatePasswordKey(param: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/ea/user/checkUpdatePasswordKey`, param)
  }

  updatePwdByKey(param: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/ea/user/updatePwdByKey`, param)
  }
}
