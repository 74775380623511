import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { ROUTER_UTILS } from '@core/utils/router.utils';

@Injectable({
  providedIn: 'root',
})
export class NoAuthGuard implements CanLoad {
  constructor(private router: Router ) {}

  canLoad(): boolean {

    return true;
  }
}
