import { Injectable } from '@angular/core';
import {
  cleanAppStorage,
  getItem,
  setItem,
  StorageItem,
  toMonth,
  treeToList,
} from '@core/utils';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { LoginUser } from '@app/models/login-user';
import { map } from 'rxjs/operators';
import { ROUTER_UTILS } from '@core/utils/router.utils';
import { Router } from '@angular/router';
import { isEmpty, pick } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class TableauService {
  public currentUrlS: BehaviorSubject<string>;

  constructor(private http: HttpClient, private router: Router) {
    this.currentUrlS = new BehaviorSubject<string>('');
  }

  public get currentUrl(): string {
    return this.currentUrlS.value;
  }

  getMenuList(param: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/ea/menu/authTree`, param)
  }

  getReallyUrl(id: string, country: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/ea/tbCtrl/routing?pCountryCode=${country}&url=${id}`)
  }
  routing(param: any) {
    return this.http.post(`${environment.apiUrl}/ea/tableau/routing`, param)
  }
  getTemplateDetail(retailerPerformance: string) {
    return this.http.get(`${environment.apiUrl}/ea/template/${retailerPerformance}`)
  }
}


