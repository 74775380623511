export const ROUTER_UTILS = {
  base: {
    home: '',
  },
  auth: {
    root: 'auth',
    login: 'login',
    resetPassword: 'resetpassword'
  },
  dashboard: {
    root: 'dashboard',
    overview: "overview",
    commonview: 'commonview',
    filtercommonview: 'filtercommonview',
    upload: 'upload',
    insight: "insight"
  },
  errorResponse: {
    notFound: '404',
  },
  config: {
    overview: 'Overview',
    sales: {
      productPerformance: 'ProductPerformance',
      performanceTopline: 'PerformanceTopline',
      productTrend: 'SalesTrendbyProduct',
      retailerTrend: 'SalesTrendbyRetailerandSeller',
      retailerPerformance: 'RetailerPerformance',
      sellerPerformance: 'SellerPerformance',
      contributionRetailer: 'ContributionbyRetailer',
      contributionSeller: 'ContributionbySeller',
      topGrowingProduct: 'TopGrowingProduct',
      performanceToplineMYTD: 'PerformanceToplineMTDYTD',
    },
    price: {
      priceTrendProduct: 'PriceTrendbyProduct',
      priceComparison: 'PriceComparison',
      priceTrendMarket: 'PriceTrendbyMarket',
      priceDispersion: 'PriceDispersion',
      priceDispersionOld: 'PriceDispersionOld',
      priceBands: 'PriceBand',
      priceDispersionNew: 'PriceDispersionNew',
      priceVsUnitCorrelation: 'PriceVsUnitCorrelation',
      lowestPriceTracker: 'LowestPriceTracker',
      pricevsSalesCorrelation: 'PriceVsSalesCorrelation'
    },
    shelf: {
      shelfShareTrend: 'ShelfShareTrends',
      shelfComparison: 'ShelfComparison',
    },
    ratings: {
      ratingsOverview: 'RatingsOverview',
      ratingsDeepProduct: 'RatingDeepDivebyProduct',
      ratingsDeepSeller: 'RatingDeepDivebySeller',
      ratingsRetailer: 'TrendedRatingbyRetailerandSeller',
      ratingsProduct: 'TrendedRatingbyProduct',
    },
    combination: {
      rawdataDownload: 'RawDataDownload',
      salesUnitsVsShelfShare: 'SalesUnitsvsShelfShare',
      // salesUnits: 'salesUnits',
      productsKPIs: 'KPIsbyProducts',
      listingKPIs: 'KPIsbyNewListing',
      indexKPIs: 'IndexbyKPIs',
      SKUEfficiency: 'SKUEfficiency',
    },
  },
};
