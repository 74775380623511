import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { DEFAULT_LOCALE_LANG, LocaleLang } from '@core/i18n/locale-lang.enum';
import { getItem, setItem, StorageItem } from '@core/utils';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class I18nService implements OnDestroy {
  destroy$ = new Subject();

  public currentLang$: BehaviorSubject<LocaleLang>;

  constructor(private translate: TranslateService) {
    this.currentLang$ = new BehaviorSubject<LocaleLang>(this.storedLanguage);
  }

  get storedLanguage(): LocaleLang {
    let lang = getItem(StorageItem.Language) as LocaleLang;
    if (lang !== LocaleLang.EN && lang !== LocaleLang.CN) {
      lang = DEFAULT_LOCALE_LANG;
    }
    return lang;
  }

  set storedLanguage(lang: LocaleLang) {
    setItem(StorageItem.Language, lang);
  }

  setLanguage(lang: LocaleLang): void {
    this.storedLanguage = lang;
    this.currentLang$.next(lang);
    this.translate.use(this.currentLang$.value);
  }

  getTranslatedValue(key: string, params?: Record<string, string | number>): string {
    return this.translate.instant(key, params);
  }
  private makeAutomaticCheck(): void {
    this.setLanguage(this.storedLanguage || DEFAULT_LOCALE_LANG);
  }
  ngOnDestroy(): void {
    this.destroy$.complete();
    this.destroy$.unsubscribe();
  }
  init(): void {
    this.makeAutomaticCheck();
  }
}
