import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StorageItem, setItem } from '@app/@core/utils';
import { FileResourceService } from '@app/services/fileResource.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@services/auth.service';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'notification-preview',
  templateUrl: './notification-preview.component.html',
  styleUrls: ['./notification-preview.component.less']
})
export class NotificationPreviewComponent implements OnInit {
  @Input()
  previewVisible: boolean = false
  @Output() previewVisibleChange = new EventEmitter<any>();
  active: boolean = false
  loading: boolean = false
  checked: boolean = false
  pdfSrc: any;
  name: string = ''

  constructor(public authService: AuthService, private translate: TranslateService,
    private fileResourceService: FileResourceService, private message: NzMessageService) {
  }
  ngOnInit(): void {
  }

  clickCancel() {
    this.previewVisible = false
    this.pdfSrc = null
    if (this.active && this.checked) {
      this.fileResourceService.inActive()
        .subscribe((res: any) => {
          setItem(StorageItem.Active, false);
          this.active = false;
        })
    }
    this.previewVisibleChange.emit(false)
  }

  initData(name: string) {
    this.loading = true
    const names = name.split("\\")
    this.name = names[names.length - 1]
    this.fileResourceService.preview(name)
      .subscribe((res: any) => {
        this.pdfSrc = res.body;
        this.loading = false
      })
  }
}
