import {
  isEmpty,
  values,
  round,
  isFinite,
  isPlainObject,
  isArray,
  isEqual,
  differenceWith,
  toString,
  isBoolean,
} from 'lodash';
import { ValueType } from '@app/models/value-type.enum';
import { AbstractControl, FormGroup, Validators } from '@angular/forms';
import { InjectionToken } from '@angular/core';
import { differenceInCalendarMonths, addMonths, format } from 'date-fns';
import { NzModalService } from 'ng-zorro-antd/modal';
import { TranslateService } from '@ngx-translate/core';



export const isValueEmpty = (value: any): boolean => {
  if (isBoolean(value)) return false;
  if (isPlainObject(value)) {
    return !values(value).some(v => !isValueEmpty(v));
  }
  if (isFinite(value)) return false;
  return isEmpty(value);
};

export const isSame = (value: any, other: any): boolean => {
  if (isArray(value) && isArray(other)) {
    if (value.length !== other.length) return false;
    value = value.sort();
    other = other.sort();
    const diff = differenceWith(value, other, isEqual);
    return isEmpty(diff);
  }
  return isEqual(value, other);
};

export const isSameObject = (obj: any = {}, otherObj: any = {}): boolean => {
  const result = Object.keys(obj).every(k => {
    const val = obj[k];
    const oVal = otherObj[k];
    if (isValueEmpty(val) && isValueEmpty(oVal)) {
      return true;
    }
    if (isPlainObject(val) && isPlainObject(oVal)) {
      return isSameObject(val, oVal);
    }
    const same = isSame(val, oVal);
    return same;
  });
  return result;
};

export const isChangesPropNotEmpty = (changeProp: any): boolean => {
  return !isEmpty(changeProp) && !isValueEmpty(changeProp.currentValue);
};

export const getValidateFormValues = (form: FormGroup): any => {
  for (const i in form.controls) {
    if (form.controls.hasOwnProperty(i)) {
      form.controls[i].markAsDirty();
      form.controls[i].updateValueAndValidity();
    }
  }
  if (!form.valid) return null;
  return form.value;
};

export const updateControlValueAndValidity = (control: AbstractControl | null, isRequired: boolean): void => {
  if (control) {
    if (isRequired) {
      control.setValidators(Validators.required);
      control.markAsDirty();
    } else {
      control.clearValidators();
      control.markAsPristine();
    }
    control.updateValueAndValidity();
  }
};

export const resetForm = (form: FormGroup) => {
  form.reset();
  for (const i in form.controls) {
    if (form.controls.hasOwnProperty(i)) {
      form.controls[i].markAsDirty();
      form.controls[i].updateValueAndValidity();
    }
  }
};

export const thousandsFormatFixed = (value: any, precision: number): string => {
  const number = parseFloat(value);
  if (Number.isNaN(number)) return '';
  return (number.toFixed(precision) + '').replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,');
};

export const percentFormatFixed = (value: any, precision: number): string => {
  if (isEmpty(value) && !value) return '';
  if (value.toString().endsWith('%')) return value;
  const number = parseFloat(value);
  if (Number.isNaN(number)) return value;
  return `${round(number * 100, precision).toFixed(precision)}%`;
};

export const formatValueByType = (value: any, type?: ValueType, precision?: number): string => {
  let result = value;
  if (type) {
    switch (type) {
      case ValueType.Decimal:
        result = thousandsFormatFixed(value, precision || 0);
        break;
      case ValueType.Percent:
        result = percentFormatFixed(value, precision || 1);
        break;
    }
  }
  return result;
};

export const isNumber = (value: any): boolean => {
  const number = parseFloat(value);
  return !Number.isNaN(number);
};
export const compare = (a: any, b: any): number => {
  if (isFinite(a) && isFinite(b)) {
    return a - b;
  }
  if (!a) {
    return -1;
  } else if (!b) {
    return 1;
  } else {
    return a.toString().localeCompare(b.toString(), 'zh');
  }
};

export const stringCharLength = (str: string): number => {
  return !str ? 0 : str.replace(/[^\x00-\xff]/g, '01').length;
};

export const getEnumKeys = <O, K extends keyof O = keyof O>(obj: O): K[] => {
  const enumKeys = Object.keys(obj).filter(k => Number.isNaN(+k)) as K[];
  return enumKeys;
};

export const toMonth = (period: string): string => {
  if (!period) return '';
  return period.replace('14', '-');
};

export const tranType = (str: string): string | boolean | number => {
  let result: string | boolean | number = str;
  try {
    result = JSON.parse(str);
  } catch (e) { }
  return result;
};

export const getColor = (colors: Array<string>, index: number) => {
  const size = colors.length;
  const _index = (index + 1) % size;
  return colors[_index];
};
export const DEFAULT_CHART_COLORS = [
  '#00a346',
  '#f0640d',
  '#a27eff',
  '#00a1a2',
  '#ad4400',
  '#f84e83',
  '#b3ccff',
  '#00bf90',
  '#682ee0',
  '#ff6d05',
  '#6699ff',
  '#b497ff',
  '#00cdd1',
  '#E4FFE4',
  '#FDD5E1',
  '#FDE5D4',
  '#FCFABE',
  '#FCFABE',
  '#C4FEFF',
  '#D6E4FF',
  '#EBE3FF',
  '#00ca23',
  '#ad4400',
  '#138238',
  '#fab0cb',
  '#15441F',
  '#B6023B',
  '#F06400',
  '#7E5C00',
  '#008F90',
  '#360093',
  '#0056ff',
  '#ff1e68',
  '#b6023b',
  '#15441f',
  '#0136e0',
  '#10009D',
  '#d20043',
  '#1d79ff',
  '#880535',
  '#006a6b',
  '#6512f0',
];
export const NIQ_COLORS = [
  '#0215C1',
  '#0136E0',
  '#0056FF',
  '#1D79FF',
  '#6699FF',
  '#00cdd1',
  '#ad4400',
  '#138238',
  '#fab0cb',
  '#FACFAF',
  '#F6A062',
  '#FF6D05',
  '#AD4400',
  '#7A3000',
  '#622700',
];


export const getMonthsByRange = (start: Date, end: Date): Date[] => {
  const months: Date[] = [];
  let startMonth = start;
  while (differenceInCalendarMonths(startMonth, end) <= 0) {
    months.push(startMonth);
    startMonth = addMonths(startMonth, 1);
  }
  return months;
};

export const treeToList = (
  tree: any[] = [],
  { idName = 'id', childrenName = 'children', level = 1, parent = null } = {},
): any[] => {
  const parentNodes = tree.map(treeItem => ({ ...treeItem, level, parent }));
  const childrenNodes = tree.map(treeItem => {
    level = Number(level) + 1;
    parent = treeItem[idName];
    return treeToList(treeItem[childrenName], { idName, childrenName, level, parent });
  });
  return Array.prototype.concat.apply(parentNodes, childrenNodes);
};

export const listToTree = (
  list: any[] = [],
  { idName = 'id', parentName = 'parent', childrenName = 'children', parent = null } = {},
): any[] => {
  const tree = list
    .filter(item => toString(item[parentName]) === toString(parent))
    .sort((item1, item2) => item1[idName] - item2[idName])
    .map(item => {
      const parent = item[idName];
      item[childrenName] = listToTree(list, { idName, parentName, childrenName, parent });
      return item;
    });
  return tree;
};
export const timeFormatSeconds = (time: any): string => {
  const d = time ? new Date(time) : new Date();
  let year = d.getFullYear();
  let month: any = d.getMonth() + 1;
  let day: any = d.getDate();
  let hours: any = d.getHours();
  let min: any = d.getMinutes();
  let seconds: any = d.getSeconds();

  if (month < 10) month = '0' + month;
  if (day < 10) day = '0' + day;
  if (hours < 0) hours = '0' + hours;
  if (min < 10) min = '0' + min;
  if (seconds < 10) seconds = '0' + seconds;

  return (year + '-' + month + '-' + day + ' ' + hours + ':' + min + ':' + seconds);
};
export function showErrorMsg(modal: NzModalService, translate: TranslateService, msgKey: string, params?: any) {
  let errMsg = ''
  translate.get(msgKey, params).subscribe(res => {
    errMsg = res;
  });
  const errModal = modal.error({
    nzTitle: `${errMsg}`,
    nzClosable: false,
    nzOkText: 'OK',
    nzOnOk: () => {
      errModal?.destroy();
    },
  });
}
export function showSuccessMsg(modal: NzModalService, translate: TranslateService, msgKey: string, params?: any) {
  let successMsg = ''
  translate.get(msgKey, params).subscribe(res => {
    successMsg = res;
  });
  const successModal = modal.success({
    nzTitle: `${successMsg}`,
    nzClosable: false,
    nzOkText: 'OK',
    nzOnOk: () => {
      successModal?.destroy();
    },
  });
}

export function deepClone(target: any) {
  const map = new WeakMap()

  function isObject(target: any) {
    return (typeof target === 'object' && target) || typeof target === 'function'
  }

  function clone(data: any): any {
    if (!isObject(data)) {
      return data
    }
    if ([Date, RegExp].includes(data.constructor)) {
      return new data.constructor(data)
    }
    if (typeof data === 'function') {
      return new Function('return ' + data.toString())()
    }
    const exist = map.get(data)
    if (exist) {
      return exist
    }
    //处理Array对象
    if (Array.isArray(data)) {
      debugger
      let ary = [];
      for (let i = 0; i < data.length; i++) {
        const item: any = data[i]
        ary.push(clone(item));
      }
      return ary;
    }
    if (data instanceof Map) {
      const result = new Map()
      map.set(data, result)
      data.forEach((val, key) => {
        if (isObject(val)) {
          result.set(key, clone(val))
        } else {
          result.set(key, val)
        }
      })
      return result
    }
    if (data instanceof Set) {
      const result = new Set()
      map.set(data, result)
      data.forEach(val => {
        if (isObject(val)) {
          result.add(clone(val))
        } else {
          result.add(val)
        }
      })
      return result
    }
    const keys = Reflect.ownKeys(data)
    const allDesc = Object.getOwnPropertyDescriptors(data)
    const result = Object.create(Object.getPrototypeOf(data), allDesc)
    map.set(data, result)
    keys.forEach(key => {
      const val = data[key]
      if (isObject(val)) {
        result[key] = clone(val)
      } else {
        result[key] = val
      }
    })
    return result
  }

  return clone(target)
}

export const DEFAULT_TIMEOUT = new InjectionToken<number>('defaultTimeout');
export const defaultTimeout = 120000;
