import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Result } from '@app/models/result';
import { SearchCondition } from '@app/models/search-condition';

@Injectable({
  providedIn: 'root',
})
export class FilterService {


  constructor(private http: HttpClient, private router: Router) {

  }


  getDateDetail(param: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/ea/date/detail`, param)
  }
  getMarketList(param: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/ea/market/list`, param)
  }
  getMarketData(param: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/ea/market/data`, param)
  }
  getRawDimension(param: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/ea/raw/dimension`, param)
  }

  saveUserPerference(param: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/ea/userPreference/save`, param)
  }
  queryUserPerference(param: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/ea/userPreference/list`, param)
  }
  deleteUserPerference(id: any): Observable<any> {
    return this.http.get(`${environment.apiUrl}/ea/userPreference/delete/${id}`)
  }
  queryUserPerferenceById(id: any): Observable<any> {
    return this.http.get(`${environment.apiUrl}/ea/userPreference/query/${id}`)
  }
  getSegmentMapping(param: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/ea/segment/getSegmentMapping`, param)
  }
  findSegmentList(param: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/ea/segment/findSegmentList`, param)
  }
}
