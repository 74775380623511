import { NgModule } from '@angular/core';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { HeaderModule } from '@components/header/header.module';
import { SliderModule } from '@components/slider/slider.module';
import { LayoutComponent } from "./layout.component"
import { SharedModule } from '@core/shared/shared.module';

@NgModule({
  declarations: [LayoutComponent],
  imports: [SharedModule, HeaderModule, SliderModule, NzLayoutModule],
  exports: [LayoutComponent],
})
export class LayoutModule {}
