import { Component, OnInit, Input } from '@angular/core';
import { ROUTER_UTILS } from '@core/utils/router.utils';
import { CateTpl } from "@app/models/nav-temp"
import { AuthService } from '@services/auth.service';
import { TableauService } from '@services/tableau.service';
import { Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';
import { TranslateService } from '@ngx-translate/core'
import { NzMessageService } from 'ng-zorro-antd/message';
import { getItem, setItem, StorageItem } from '@app/@core/utils/local-storage.utils';


@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.less']
})
export class SliderComponent implements OnInit {
  @Input() isCollapsed!: boolean
  currentUrlS: any
  currentUrl: string = ''
  currentEnv: string = ''
  currentConfirmGroupId_obs: any
  navigationList: Array<CateTpl> = [];

  constructor(private authService: AuthService, private router: Router, private tableauServce: TableauService, private modal: NzModalService, private translate: TranslateService, private message: NzMessageService) {
    this.currentUrlS = this.tableauServce.currentUrlS
  }

  ngOnInit(): void {
    this.currentConfirmGroupId_obs = this.authService.confirmGroupIdSubject.subscribe((isComfirmed: Boolean) => {
      //如果是登录状态,获取目录,否则,跳到登录页
      if (isComfirmed) {
        this.getMenuList()
      } else {
        // this.router.navigate(['/auth/login/'])
      }
    })

  }
  isOpenSubMenu(subMenuDTOS: Array<CateTpl>): boolean {
    let isOpen = false
    subMenuDTOS.forEach(element => {
      const currentHref = decodeURIComponent(location.href)
      const currentHrefs = currentHref.split('/')
      const urls = element.url.split('/')
      if (currentHrefs[currentHrefs.length - 1] === urls[urls.length - 1]) isOpen = true
    });
    return isOpen
  }
  //是否选中当前
  isSelected(menuItem: CateTpl): boolean {
    const currentHref = decodeURIComponent(location.href)
    const currentHrefs = currentHref.split('/')
    if (menuItem.url) {
      const urls = menuItem.url.split('/')
      if (currentHrefs[currentHrefs.length - 1] === urls[urls.length - 1]) {
        return true
      }
    }
    return false
  }
  navMenuClick(menuItem: any) {
    if (menuItem.url) {
      if (menuItem.url.indexOf('/views') === -1) {
        this.router.navigate([`/${ROUTER_UTILS.dashboard.root}/${menuItem.url}`])
      } else {
        if (this.currentEnv === 'DIAGEO') {
          this.router.navigate([`/${ROUTER_UTILS.dashboard.root}/${ROUTER_UTILS.dashboard.commonview}/`, menuItem.url])
        } else {
          this.router.navigate([`/${ROUTER_UTILS.dashboard.root}/${ROUTER_UTILS.dashboard.filtercommonview}/`, menuItem.url])
        }
      }
    } else {
      //没有配置路径
      this.translate.get('menu.urlEmptyMsg').subscribe(res => {
        const errMsg = res;
        this.message.create('error', errMsg);
      });
    }
  }
  async getMenuList() {
    try {
      const currentCountry = <string>getItem(StorageItem.Country) || ''
      const currentCategory = <string>getItem(StorageItem.Category) || ''
      this.tableauServce.getMenuList({ countryCode: currentCountry, categoryCode: currentCategory }).subscribe((res: any) => {
        if (res.statusCode === 200) {
          if (res.data && res.data.length) {
            //标准版结构
            if (res.data[0]?.subMenuDTOS.length) {
              this.navigationList = res.data[0]?.subMenuDTOS
              let firstPage: any = null
              if (!this.navigationList[0].url) {
                const firstmenus: any = this.navigationList[0].subMenuDTOS
                firstPage = firstmenus[0]
              } else {
                firstPage = this.navigationList[0]
              }

              this.currentEnv = res.data[0].menuCode
              setItem(StorageItem.MenuEnv, this.currentEnv);
              if (firstPage.url.indexOf('/tableau/views') === -1) {
                this.router.navigate([`/${ROUTER_UTILS.dashboard.root}/${firstPage.url}`])
              } else {
                this.router.navigateByUrl('', { skipLocationChange: true }).then(() => {
                  if (this.currentEnv === 'DIAGEO') {
                    this.router.navigate([`/${ROUTER_UTILS.dashboard.root}/${ROUTER_UTILS.dashboard.commonview}/`, firstPage.url])
                  } else {
                    this.router.navigate([`/${ROUTER_UTILS.dashboard.root}/${ROUTER_UTILS.dashboard.filtercommonview}/`, firstPage.url])
                  }
                });
              }
            } else {
              this.navigationList = res.data
              this.router.navigateByUrl('', { skipLocationChange: true }).then(() => {
                if (this.navigationList[0].url.indexOf('/views') === -1) {
                  this.router.navigate([`/${ROUTER_UTILS.dashboard.root}/${this.navigationList[0].url}`])
                } else {
                  this.router.navigate([`/${ROUTER_UTILS.dashboard.root}/${ROUTER_UTILS.dashboard.commonview}/`, this.navigationList[0].url])
                }
              });
            }

          } else {
            //返回的目录为空
            this.showErrorMsg('menu.menuListEmptyMsg')
          }
        } else {
          this.showErrorMsg('menu.getMenuListErrMsg')
        }
      });
    } catch (e) {
      this.showErrorMsg('menu.getMenuListErrMsg')
    }
  }
  showErrorMsg(msgKey: string) {
    let errMsg = ''
    this.translate.get(msgKey).subscribe(res => {
      errMsg = res;
    });
    const errModal = this.modal.error({
      nzTitle: `${errMsg}`,
      nzClosable: false,
      nzOkText: 'OK',
      nzOnOk: () => {
        errModal?.destroy();
      },
    });
  }
  ngOnDestroy() {
    if (this.currentConfirmGroupId_obs) this.currentConfirmGroupId_obs.unsubscribe();
  }
}
