import { NgModule } from '@angular/core';
import { NotificationListComponent } from './notification-list/notification-list.component';
import { SharedModule } from '@core/shared/shared.module';
import { NotificationPreviewComponent } from './notification-preview/notification-preview.component';
@NgModule({
  declarations: [NotificationListComponent, NotificationPreviewComponent],
  imports: [SharedModule],
  exports: [NotificationListComponent],
})
export class NotificationModule { }
