import { Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { Observable } from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd/message';
import { TranslateService } from '@ngx-translate/core'
import { ViewEncapsulation } from '@angular/core';
import { TableColumn } from '@app/models/table-column';

@Component({
  selector: 'simple-table',
  templateUrl: './simple-table.component.html',
  styleUrls: ['./simple-table.component.less'],
  encapsulation: ViewEncapsulation.Emulated
})
export class SimpleTableComponent implements OnInit {
  @Input()
  tableData: Array<any> = []
  @Input()
  columns: Array<TableColumn> = []
  @Output() drawerVisibleChange = new EventEmitter<boolean>();
  @Input()
  pageVo: any = { total: 10, pageSize: 10, pageIndex: 1 }
  loading = false;
  @ContentChild(TemplateRef) status!: TemplateRef<any>;
  constructor(public authService: AuthService, private message: NzMessageService, private translate: TranslateService) {
  }
  ngOnInit(): void {

  }
  onClickSearch($event: any) { }
}
