import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class FileResourceService {
  constructor(private http: HttpClient, private router: Router) {

  }
  getFilesList(fileType: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/ea/fileResource/fileLists/${fileType}`)
  }
  downloadUserGuild(language: string): Observable<any> {
    const options: Object = {
      responseType: 'arraybuffer',
      observe: 'response'
    }
    return this.http.post(`${environment.apiUrl}/ea/fileResource/userGuid/download`, language, options)
  }

  preview(name: string): Observable<any> {
    const options: Object = {
      responseType: 'arraybuffer',
      observe: 'response'
    }
    return this.http.post(`${environment.apiUrl}/ea/fileResource/notification/preview`, name, options)
  }

  isActive(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/ea/fileResource/notification/isActive`)
  }
  inActive(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/ea/fileResource/notification/inActive`)
  }
}
