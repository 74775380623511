import { getEnumKeys } from '@core/utils/common.utils';

export enum StorageItem {
  Token = 'EA/token',
  AuthUser = 'EA/user',
  Language = 'EA/Lang',
  Country = 'EA/Country',
  Category = 'EA/Category',
  MenuEnv = "MenuEnv",
  GroupId = 'EA/GroupId',
  Segement = 'EA/Segement',
  Active = 'EA/IsActive',
}

export const getStorage = (itemName: StorageItem): Storage => {
  if (!itemName) return sessionStorage;
  return sessionStorage;
};

export const getItem = (itemName: StorageItem): unknown | null => {
  const item = getStorage(itemName).getItem(itemName);
  return item ? JSON.parse(item) : null;
};

export const setItem = (itemName: StorageItem, value: unknown): void => {
  getStorage(itemName).setItem(itemName, JSON.stringify(value));
};

export const removeItem = (itemName: StorageItem): void => {
  getStorage(itemName).removeItem(itemName);
};

export const cleanStorage = (): void => {
  localStorage.clear();
  sessionStorage.clear();
};

export const cleanAppStorage = (): void => {
  const storageItems = getEnumKeys(StorageItem);
  storageItems.forEach(enumKey => {
    const enumVal = StorageItem[enumKey];
    if (enumVal.startsWith('EA')) {
      removeItem(enumVal);
    }
  });
};